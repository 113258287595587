var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "ui-tab-bar": true, "ui-tab-bar--as-block": _vm.asBlock } },
    [_c("div", { staticClass: "ui-tab-bar__tabs" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }