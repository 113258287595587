<template>
  <div
    :id="id"
    :class="{
      'ui-tab': true,
      'ui-tab--active': isActive,
      'ui-tab--disabled': disabled,
    }"
    @click="setActive"
    @change="change"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "UiTabBarTab",
  inject: ["getActiveTab", "setActiveTab"],
  props: {
    value: {
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    active: true,
  }),
  computed: {
    isActive() {
      return this.getActiveTab() === this.value;
    },
  },
  methods: {
    change() {
      this.$emit("change");
    },
    setActive() {
      this.$emit("click");
      if (!this.disabled) {
        this.setActiveTab(this.value);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ui-tab {
  padding: 14px 10px 10px;
  cursor: pointer;
  position: relative;
  transition: all 300ms ease-out;
  display: flex;
  justify-content: center;
  color: #18181b;
  font-family: $font-family-bebas;
  font-style: normal;
  font-weight: bold;
  line-height: 132.5%;
  letter-spacing: 0.18em;
  font-size: 10px;
  @media screen and (min-width: $screen-sm) {
    padding: 14px 13px 10px;
    font-size: 14px;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }

  &:before {
    display: block;
    content: " ";
    position: absolute;
    height: 3px;
    width: 100%;
    background: #e14413;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all 300ms ease-out;
  }

  &:hover {
    color: #e14313;
  }

  &:after {
    display: block;
    content: " ";
    background-color: rgb(243, 243, 243);
    position: absolute;
    right: 0;
    height: 18px;
    top: 12px;
    width: 1px;
  }

  &--active {
    color: #e14313;

    &:before {
      opacity: 1;
    }
  }

  &--disabled {
    opacity: 0.3;
  }
}
</style>
