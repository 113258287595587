<template>
  <div :class="{ 'ui-tab-bar': true, 'ui-tab-bar--as-block': asBlock }">
    <div class="ui-tab-bar__tabs">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "UiTabBar",
  provide: function () {
    return {
      getActiveTab: this.getActiveTab,
      setActiveTab: this.setActiveTab,
    };
  },
  props: {
    value: {},
    uncheckable: {
      type: Boolean,
      default: false,
    },
    asBlock: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeTab: null,
  }),
  watch: {
    value() {
      this.activeTab = this.value;
    },
  },
  created() {
    this.activeTab = this.value;
  },
  methods: {
    getActiveTab() {
      return this.activeTab;
    },
    setActiveTab(value) {
      if (this.uncheckable && this.activeTab === value) {
        this.activeTab = "";
      } else {
        this.activeTab = value;
      }
      this.$emit("input", this.activeTab);
    },
  },
};
</script>

<style scoped lang="scss">
.ui-tab-bar {
  background-color: #ffffff;
  display: inline-flex;
  box-shadow: 8px 6px 15px rgba(0, 0, 0, 0.07);

  &--as-block {
    display: flex;
  }

  &__tabs {
    display: flex;
    flex: 1;
    box-shadow: inset 0 0 0 1px rgba(24, 24, 27, 0.03);
  }
}
</style>
