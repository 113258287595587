var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "ui-tab": true,
        "ui-tab--active": _vm.isActive,
        "ui-tab--disabled": _vm.disabled,
      },
      attrs: { id: _vm.id },
      on: { click: _vm.setActive, change: _vm.change },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }